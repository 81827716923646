import { render, staticRenderFns } from "./ProductContentBannerPoints.vue?vue&type=template&id=50f9b9aa&scoped=true"
import script from "./ProductContentBannerPoints.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProductContentBannerPoints.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ProductContentBannerPoints.vue?vue&type=style&index=0&id=50f9b9aa&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f9b9aa",
  null
  
)

export default component.exports