import Vue from 'vue';
import { abPlugin } from '@webplatform/asbis-ui';
import * as allData from '@webplatform/asbis-ui/dist/sections';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { sectionsWithDataProps, componentsWithDetails, ...sections } = allData;
Vue.config.ignoredElements = [
  'swiper-container',
  'swiper-slide',
  'sl-tab',
  'sl-tab-group',
  'sl-tab-panel',
];

Object.keys(sections).forEach((sectionName) => {
  if (sectionName !== 'CtaSectionBanner')
    Vue.component(sectionName, sections[sectionName]);
});
// eslint-disable-next-line func-names
export default function ({ $gsap, $ScrollTrigger }) {
  Vue.use(abPlugin, {
    gsap: $gsap,
    ScrollTrigger: $ScrollTrigger,
  });
}
