
import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  useStore,
  watch,
  useContext,
} from '@nuxtjs/composition-api';

import useForm from '~/composables/useForm';
import useCountries from '~/composables/useCountries';
import useModalDialog from '~/composables/useModalDialog';

interface FormData {
  name: string;
  phone: string;
  email: string;
  country: string;
  company: string;
  personalData: boolean;
}

const FORM_DEFAULTS = {
  name: '',
  phone: '',
  email: '',
  country: '',
  company: '',
  personalData: false,
};

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const form = ref();
    const { formPending, confirm, submit, recaptcha, isSubmitDisabled } =
      useForm();
    const tryDemoModal = useModalDialog('try-demo-form');
    const infoModal = useModalDialog('try-demo-form-info');
    const { $gtm, nuxtState } = useContext();

    const formData = reactive<FormData>({
      ...FORM_DEFAULTS,
    });
    const isFormStartEventSended = ref(false);

    const { getCountriesList } = useCountries();
    const countries = getCountriesList();
    const openFile = () => {
      if (!props.data.file?.link) return;
      window.open(props.data.file.link, '_self');
    };

    const clearForm = () => {
      confirm.value = false;

      Object.assign(formData, {
        ...FORM_DEFAULTS,
      });

      nextTick(() => form.value.reset());
    };

    const submitForm = async () => {
      try {
        const formId = nuxtState.config.softwareTrialFormUuid;
        await submit({ ...createFormPayload(formData) }, formId);
        openFile();
        clearForm();
        tryDemoModal.close();
        infoModal.open();
      } catch (error) {
        console.error('Error submitting form', error);
      }
    };

    const pushFormStartEvent = () => {
      if (!isFormStartEventSended.value) {
        $gtm.push({
          event: 'form_start',
        });
      }
      isFormStartEventSended.value = true;
    };

    const createFormPayload = (formModel: FormData) => {
      return {
        lang: store.getters['config/getActiveLanguage'],
        website_url: store.getters['config/getConfig'].domain,
        lead_source: window.location.href,

        first_name: formModel.name,
        mobile_phone: formModel.phone,
        email: formModel.email,
        country: formModel.country,
        company_name: formModel.company,
      };
    };

    watch(formData, () => {
      pushFormStartEvent();
    });
    watch(confirm, () => {
      pushFormStartEvent();
    });
    return {
      infoModal,
      form,
      formData,
      confirm,
      countries,
      formPending,
      recaptcha,
      submitForm,
      isSubmitDisabled,
    };
  },
});
