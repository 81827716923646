import { ref, useMeta, useContext } from '@nuxtjs/composition-api';
import type { Params } from '@webplatform/nuxt-cms-js-sdk';

const usePage = () => {
  const { error, ssrContext, $api } = useContext();
  const page = ref();
  const fetchPage = async (slug: string, params?: Params) => {
    try {
      const { data } = await $api.pagesService.getPageBySlug(slug, params);
      page.value = data;
    } catch (e) {
      if (ssrContext) ssrContext.res.statusCode = 404;
      else error({ statusCode: 404 });
    }
  };

  useMeta(() => ({
    title: page.value?.seo_title,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: page.value?.seo_description,
      },
      {
        property: 'og:title',
        content: page.value?.seo_description,
      },
    ],
  }));
  return {
    page,
    fetchPage,
  };
};

export default usePage;
