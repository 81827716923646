import { render, staticRenderFns } from "./SolutionsCarouselSlideMobile.vue?vue&type=template&id=0050ec23&scoped=true"
import script from "./SolutionsCarouselSlideMobile.vue?vue&type=script&setup=true&lang=ts"
export * from "./SolutionsCarouselSlideMobile.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SolutionsCarouselSlideMobile.vue?vue&type=style&index=0&id=0050ec23&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0050ec23",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RichVideo: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@2/components/RichVideo.vue').default,UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@2/components/ui/UiIcon.vue').default,UiSectionTitle: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@2/components/ui/UiSectionTitle.vue').default})
